import React from "react"

import { Layout } from "../components"
import { ContactForm } from "@ultralight-io/components"

export default function Example() {
  return (
    <Layout>
      <div className="min-h-3/4 flex items-center justify-center bg-white dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-4">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">There was an issue with your registration.</h2>
            <p className="mt-6 text-center text-xl text-gray-900 dark:text-white">Please try again or contact us about your issue.</p>
          </div>
          <a
            href="/registration"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light"
          >
            Try registration again
          </a>
          <a
            href="/"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-primary bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light"
          >
            Return to the Home page
          </a>
        </div>
      </div>
      <ContactForm action="/api/contact" />
    </Layout>
  )
}